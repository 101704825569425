import { styled } from 'linaria/react';

export const Tile = styled.div`
    width: 700px;
    height: 700px;
    border-radius: 100px;
    margin-top: 60px;
    background: linear-gradient(145deg, #787878, #656565);
    box-shadow:  28px 28px 42px #616161,
                -28px -28px 42px #7f7f7f;
`;
