import { Switch, Route } from 'react-router-dom';

import { AppContainer, Tile } from 'ui';
import { Header } from 'components';

const App: React.FC = () => (
    <AppContainer>
        <>
            {/* <Header /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tile />
                <Tile />
            </div>
            {/* <Switch>
                <Route exact path='/'>
                    <div>HOME</div>
                </Route>
                <Route exact path='/1'>
                    <div>123</div>
                </Route>
                <Route exact path='/2'>
                    <div>321</div>
                </Route>
            </Switch> */}
        </>
    </AppContainer>
);

export default App;
