import * as s from './AppContainer.styled';

interface TAppContainer {
    children: React.ReactChild;
}

const AppContainer: React.FC<TAppContainer> = ({ children }) => (
    <s.AppContainer>
        {children}
    </s.AppContainer>
);

export default AppContainer;
